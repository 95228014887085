.film-one-page {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  padding: 0;
  -webkit-transform-style: preserve-3d;
  transform: translate3d(0px, 0%, 0px);
  transition: all 1000ms ease;
}

.film-one-page .film-one-page-item {
  padding: 0px 50px 80px 50px;
  width: 100%;
  height: 100vh;
  overflow: hidden;

}


.film-one-page .film-one-page-item .film-one-page-item-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  background-image: url(/src/asset/bg_10.jpg);
  display: flex;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text {
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 96;
  transform: translate(-50%, -50%);
  z-index: 97;

}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .time {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #696969;
  text-transform: uppercase;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .title {
  font-size: 80px;
  font-weight: 700;
  font-family: Merriweather;
  color: #fff;
  line-height: 90px;
  margin-top: 20px;
}


@media only screen and (max-width: 600px) {
  .film-one-page .film-one-page-item .film-one-page-item-inner .text .title {
    font-size: 50px;
    line-height: 60px;
  }
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta {
  margin-top: 20px;
  font-size: 14px;
  color: #ababab;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta>span {
  display: inline-block;
  margin-right: 20px;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta>span:last-child {
  margin-right: 0;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta>span.view:before {
  content: '\f133';
  font-family: Ionicons;
  font-size: 16px;
  color: #da0e2b;
  margin-right: 10px;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .text .meta>span.comment:before {
  content: '\f11a';
  font-family: Ionicons;
  font-size: 16px;
  color: #da0e2b;
  margin-right: 10px;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video {
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 96;
  transform: translate(0%, -50%);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner {
  position: relative;
  overflow: hidden;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play {
  z-index: 97;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play:before {
  content: '';
  width: 70px;
  height: 70px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  background-color: #da0e2b;
  opacity: .3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 97;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner .video-play:after {
  content: '\f215';
  font-family: Ionicons;
  color: #fff;
  font-size: 18px;
  width: 56px;
  height: 56px;
  line-height: 56px;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  border-radius: 28px;
  background-color: #da0e2b;
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 98;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video .video-inner img {
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -ms-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.film-one-page .film-one-page-item .film-one-page-item-inner .video:hover .video-inner img {
  transform: scale(1.1);
}

.onepage-pagination {
  position: fixed;
  left: 15px;
  top: 50%;
  z-index: 5;
  list-style: none;
  margin: 0;
  padding: 0;
}

.onepage-pagination li {
  padding: 0;
  text-align: center;
}

.onepage-pagination li a {
  display: block;
  height: 40px;
  line-height: 40px;
  position: relative;
}

.onepage-pagination li a:before {
  content: "0" attr(data-index);
  font-family: 'Montserrat', Tahoma, Verdana;
  font-size: 12px;
  font-weight: 700;
  color: #ababab;
  -webkit-transition: all linear .5s;
  -moz-transition: all linear .5s;
  -ms-transition: all linear .5s;
  -o-transition: all linear .5s;
  transition: all linear .5s;
}

.onepage-pagination li a:after {
  content: '';
  width: 0;
  height: 1px;
  display: block;
  background-color: #da0e2b;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transition: width linear .5s;
  -moz-transition: width linear .5s;
  -ms-transition: width linear .5s;
  -o-transition: width linear .5s;
  transition: width linear .5s;
}

.onepage-pagination li a.current:before {
  font-size: 18px;
  color: #333;
}

.onepage-pagination li a.current:after {
  width: 80px;
}


@media (max-width: 769px) {
  .film-one-page .film-one-page-item .film-one-page-item-inner {
    justify-content: center;
  }

  .video__wrapper {
    display: none;
  }
}
