.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.wrapper {
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: black;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  background-position: center;
  padding: 0 0 30px 100px;
}

@media only screen and (max-width : 480px) {
  .wrapper {
    padding: 20px 10px;
    text-align: center;
  }

  .banner__info {
    width: 100%;
  }
}
